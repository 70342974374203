/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import ZgsgInfo from "./ZgsgInfo";
export default {
  components: {
    ZgsgInfo
  },
  data() {
    return {
      showInfo: false,
      selectdx: false,
      list: [],
      mount: [],
      selectList: [],
      result: [],
      itemString: "",
      zgsgData: {}
    };
  },
  created() {
    this.getCgwpInfo();
  },
  mounted() {},
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var url = _baseUrl + `/sys/db/sysSqlCustomQuery/queryForJson_hqsyxm.do`;
      this.$ajax.post(url).then(res => {
        this.list = res.data.data;
        this.mount = this.list;
      });
    },
    onSelects() {
      this.selectList.push("全部");
      let oneNum = 100;
      for (let i = 1; i < 10; i++) {
        this.selectList.push(oneNum + i);
      }
      for (let i = 1; i < 22; i++) {
        this.selectList.push(200 + i);
      }
      for (let i = 1; i < 45; i++) {
        this.selectList.push(300 + i);
      }
      this.selectdx = true;
    },
    onSelectConfirm(value) {
      this.list = [];
      if (value == "全部") {
        this.list = this.mount;
        // this.getCgwpInfo();
      } else {
        this.mount.map(item => {
          if (value == item.ZXDM.slice(0, 3)) {
            this.list.push(item);
          }
        });
      }
      this.selectdx = false;
    },
    onInfo(item) {
      this.showInfo = true;
      this.zgsgData = item;
    },
    //确定
    onDefine() {
      // if (this.result.length < 1) {
      //   this.$emit("tdList",this.result);
      // }else{
      // let result;
      // this.result.map((item)=>{
      //   let resultStr = JSON.stringify(this.result);
      //   let itenStr = JSON.stringify(item);
      //   if (resultStr.indexOf(itenStr)==-1) {
      //     result.push(item);
      //   }
      // })
      this.$emit("tdList", this.result);
      // }
    }
  }
};