/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";
import { Dialog } from 'vant';
import { unique } from "@/assets/app.js";
import RowInfo from "./RowInfo";
export default {
  name: "TablePge",
  components: {
    RowInfo
  },
  data() {
    return {
      searchValbh: "",
      searchValmc: "",
      showInfo: false,
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: {},
      itemString: "",
      dataInfo: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
  },
  mounted() {},
  methods: {
    onRadio(item) {},
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var formdata = new FormData();
      formdata.append("pageIndex", pageNum);
      formdata.append("pageSize", this.pageSize);
      if (this.searchValbh != "" || this.searchValmc != "") {
        formdata.append("Q_BH_S_LK", this.searchValbh);
        formdata.append("Q_MC_S_LK", this.searchValmc);
      }
      var url = _baseUrl + `/dev/cus/customData/sbtzxx/getData.do?single=true&_winid=w6778&_t=159825`;
      this.$ajax.post(url, formdata).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          this.mount.push(item);
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 20);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        // this.prePage = pageNum;
        this.getCgwpInfo();
      } else {
        Dialog.alert({
          message: '请正确输入页数！'
        });
        // Toast("请正确输入页数！");
      }
    },

    //搜索
    onSearch() {
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    onClear() {
      this.searchValbh = "";
      this.searchValmc = "";
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    onInfo(item) {
      console.log(item);
      this.showInfo = true;
      this.dataInfo = item;
    },
    //确定
    onSelects() {
      // console.log(this.result)
      this.$emit("tdObj", this.result);
    }
  }
};