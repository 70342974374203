/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import TablePage from "../../../common/sbsqRadio/TablePage";
import ZgsgList from "../../../common/zgsgList/ZgsgList";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, convertKey, format, unique, convertCurrency } from "@/assets/app.js";
export default {
  name: "lcfqHksq",
  components: {
    LCFQheader,
    TablePage,
    ZgsgList
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "人员能力资格确认和上岗授权",
      //下拉
      showListpage: false,
      addList: [],
      delectList: [],
      showSqsj: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      actDefId: "",
      data: {
        xm: "",
        xb: "",
        csny: "",
        whcd: "",
        byyx: "",
        sxzy: "",
        gzbm: "",
        zc: "",
        sgzh: "",
        sqxms: "",
        ksqxm: [],
        qxsq: [],
        fj: []
      },
      fj: [],
      ksqxmMax: 0
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      console.log(solId);
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      // if (typeof(busad) != "undefined"){
      if (busad != "") {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          this.data = res.data;
          let urlUser1 = _baseUrl + `/mobile/oa/getInfosSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
          this.$ajax.post(urlUser1).then(resdlr => {
            let dlr = [{
              id: resdlr.data.rowList[0].userId,
              text: resdlr.data.rowList[0].fullname
            }];
            this.data.dlrName = JSON.stringify(dlr);
          });
        });
      } else {
        this.getInfo();
      }
    },
    //获取信息
    getInfo() {
      let getUrl = _baseUrl + "/zgqr/zgrz/zgRz/getUser.do";
      this.$ajax.post(getUrl).then(res => {
        this.data.xm = res.data.identityName;
        if (res.data.sex == "Female") {
          this.data.xb = "女";
        } else {
          this.data.xb = "男";
        }
        this.data.csny = format(res.data.birthday);
        this.data.sgzh = res.data.identityId;
        let xxzy = JSON.stringify({
          ZGBH: res.data.identityId
        });
        let xxzyForm = new FormData();
        xxzyForm.append("params", xxzy);
        let byUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqxl.do";
        this.$ajax.post(byUrl, xxzyForm).then(res => {
          this.data.byyx = res.data.data[0].SCHOOL;
          this.data.sxzy = res.data.data[0].SPECIALTY;
        });
        let bmgw = JSON.stringify({
          PARTY2_: res.data.identityId
        });
        let bmgwForm = new FormData();
        bmgwForm.append("params", bmgw);
        let zwUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqbm.do";
        this.$ajax.post(zwUrl, bmgwForm).then(res => {
          this.data.gzbm = res.data.data[0].NAME_;
          if (res.data.data[0].NAME_ == res.data.data[1].NAME_) {
            this.data.zc = res.data.data[3].NAME_ + res.data.data[res.data.data.length - 1].NAME_;
          } else {
            this.data.zc = res.data.data[2].NAME_;
          }
        });
        let closeObj = JSON.stringify({
          RYID: res.data.identityId
        });
        let closeForm = new FormData();
        closeForm.append("params", closeObj);
        let closeUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqryxm.do";
        this.$ajax.post(closeUrl, closeForm).then(res => {
          this.data.sqxms = res.data.data.length;
          this.ksqxmMax = 5 - this.data.sqxms;
          res.data.data.map(item => {
            this.delectList.push(item.XMDM);
          });
        });
        let whcdObj = JSON.stringify({
          USER_ID_: res.data.identityId
        });
        let whcdForm = new FormData();
        whcdForm.append("params", whcdObj);
        let whcdUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqxm.do";
        this.$ajax.post(whcdUrl, whcdForm).then(res => {
          this.data.whcd = res.data.data[0].EDUCATION;
        });
      });
    },
    onConfirmSqsj(timestamp) {
      this.data.sqsj = format(timestamp);
      this.showSqsj = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdList(list) {
      this.addList = list;
      this.showListpage = false;
    },
    beforeRead(file) {
      if (this.data.fj.length > 0) {
        let fjStr = this.onFile();
        if (fjStr.indexOf(file.name) == -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    //上传附件
    afterRead(file) {
      let wnsc = new FormData();
      wnsc.append("from", "SELF");
      wnsc.append("types", "");
      wnsc.append("sysTreeId", "");
      wnsc.append("id", "WU_FILE_0");
      wnsc.append("name", file.file.name);
      wnsc.append("type", file.file.type);
      wnsc.append("lastModifiedDate", file.file.lastModifiedDate);
      wnsc.append("size", file.file.size);
      wnsc.append("file", file.file);
      var fjObj;
      let wnscUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(wnscUrl, wnsc).then(res => {
        fjObj = {};
        fjObj.fileId = res.data.data[0].fileId;
        fjObj.fileName = res.data.data[0].fileName;
        fjObj.totalBytes = res.data.data[0].totalBytes;
        this.fj.push(fjObj);
      });
    },
    beforeDelete(file) {
      for (let i = 0; i < this.fj.length; i++) {
        if (file.file.name == this.fj[i].fileName) {
          this.fj.splice(i, 1);
        }
      }
      return true;
    },
    onFile() {
      let fjObj = {};
      let fj = [];
      this.data.fj.map(item => {
        fjObj = {};
        fjObj.fileId = item.file.lastModified;
        fjObj.fileName = item.file.name;
        fjObj.totalBytes = item.file.size;
        fj.push(fjObj);
      });
      return JSON.stringify(fj);
    },
    datas() {
      // let ksqxm;
      let ksqxmList = [];
      let ksqxmListIdDh;
      if (this.data.ksqxm.length == 0) {
        ksqxmList = "";
      } else {
        let ksqxmListId = [];
        let ksqxmObj = {};
        this.data.ksqxm.map(xmdm => {
          ksqxmListId.push(xmdm.ZXDM);
          ksqxmObj = {};
          ksqxmObj.id = xmdm.ZXDM;
          ksqxmObj.text = xmdm.ZXMC;
          ksqxmObj.cs = xmdm.JCCS;
          ksqxmList.push(ksqxmObj);
        });
        ksqxmListIdDh = ksqxmListId.join();
      }
      let qxsq;
      if (this.data.qxsq.length == 0) {
        qxsq = "";
      } else {
        qxsq = this.data.qxsq.join();
      }
      // let qxsq = this.data.qxsq.join();
      let fj = JSON.stringify(this.fj);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            xm: this.data.xm,
            xb: this.data.xb,
            csny: this.data.csny,
            whcd: this.data.whcd,
            byyx: this.data.byyx,
            sxzy: this.data.sxzy,
            gzbm: this.data.gzbm,
            zc: this.data.zc,
            sgzh: this.data.sgzh,
            sqsj: this.data.sqsj,
            // zdm:"",
            // zdm_name:"",
            sqxms: this.data.sqxms,
            ksqxm: ksqxmListIdDh,
            qxsq: qxsq,
            zyzs: this.data.zyzs,
            jznl: this.data.jznl,
            gzjl: this.data.gzjl,
            bz: this.data.bz,
            fj: fj,
            // yz:this.data.sqxms,
            // ksqxm_name:ksqxmListIdDh,
            qxsq_name: qxsq
            // SUB_syxm:ksqxmList
          }
        }]
      };

      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      // this.datas()
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};