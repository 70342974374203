/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  props: {
    zgsgData: {}
  },
  data() {
    return {
      // data:this.zgsgInfo
    };
  }
};