/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  props: {
    dataInfo: {
      default: ""
    }
  },
  data() {
    return {
      // data:this.dataInfo
    };
  },
  mounted() {}
};